import * as React from 'react';
import { createElement } from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { library, icon } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FancyTitle from './fancy-title';
import * as icons from '../icons/icon-pack';

function References({
  title = 'Referenzen & Shopify-Projekte', titlePosition = 'text-center', text, showMore = true, filterLink = null, titleTag = 'h1', referencesTitleTag = 'h2', limit, subtitle,
}) {
  library.add({ ...icons });

  const data = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(references)/"}} sort: {fields: frontmatter___weight, order: ASC}) {
          nodes {
            frontmatter {
              title
              image {
                childImageSharp {
                  gatsbyImageData(
                    width: 480
                    placeholder: BLURRED
                    breakpoints: [320, 480, 560, 768, 1024, 1280, 1440, 1920]
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              link
              logo {
                publicURL
                extension
              }
              logoWidth
              logoHeight
              weight
            }
          }
        }
      }
    `,
  );

  let references = data.allMarkdownRemark.nodes;
  if (filterLink != null) {
    references = references.filter((reference) => filterLink.includes(reference.frontmatter.link));
    const _references = [];
    references.forEach((reference) => {
      const findReference = _references
        .find((ref) => ref.frontmatter.link === reference.frontmatter.link);
      if (!findReference) _references.push(reference);
    });
    references = _references;
  }

  /**
   * Sort array by title -> see https://stackoverflow.com/a/45544166
   */

  return (
    <div>
      <FancyTitle
        text={title}
        className={`${text ? 'my-0' : 'my-10'} ${titlePosition}`}
        tag={titleTag}
        subtitle={subtitle}
        textAlign="text-center lg:text-center"
        marginBottom="mb-20"
      />
      {text && (
        <div className="hidden lg:block mb-24 text-xl">{text}</div>
      )}
      <div className="grid gap-4 grid-cols-1 s:grid-cols-2 md:grid-cols-3 xl:grid-cols-4">
        {references && references.map((edge, index) => {
          if (limit && index >= limit) return false;
          const reference = edge.frontmatter;
          const image = getImage(reference.image);
          const cardContent = (
            <>
              <div className="absolute w-full h-full bg-green-800 z-10 opacity-75 hover:bg-slate-700 hover:opacity-20 transition" />
              <div className="m-auto z-20 pointer-events-none">
                {
                  createElement(
                    referencesTitleTag,
                    { className: 'sr-only' },
                    reference.title,
                  )
                }
                {
                  reference.logo?.publicURL && (
                    <img
                      src={reference.logo.publicURL}
                      width={reference.logoWidth}
                      height={reference.logoHeight}
                      className="max-h-8 s:max-h-12 xl:max-h-16 h-full max-w-[100px] s:max-w-[150px] xl:max-w-[175px] w-96 object-contain"
                      alt={reference.title}
                      loading="lazy"
                    />
                  )
                }
              </div>
            </>
          );
          return (
            <div
              title={reference.title}
              className={`relative h-full aspect-[16/9] overflow-hidden${index > 4 && showMore ? ' hidden lg:flex' : ' flex'}`}
              key={reference.title}
            >
              {image && (
                <GatsbyImage
                  image={image}
                  className="w-full"
                  alt={reference.title}
                />
              )}
              {reference.link
                ? (
                  <Link
                    to={`/references/${reference.link}`}
                    className="absolute w-full h-full flex"
                  >
                    {cardContent}
                  </Link>
                )
                : <div className="absolute w-full h-full flex">{cardContent}</div>}
            </div>
          );
        })}
        {showMore && (
          <Link to="/references" className="flex bg-green-800 hover:bg-green-900 text-white group aspect-[16/9]">
            <div className="flex m-auto items-center group-hover:scale-105 transition-transform">
              <span className="mr-2">Mehr</span>
              <FontAwesomeIcon icon={icon({ prefix: 'far', iconName: 'arrow-right' })} size="2x" />
            </div>
          </Link>
        )}
      </div>
    </div>
  );
}

export default References;
